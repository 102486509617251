import { shallowRef } from 'vue'

import AccountPanel from '@feature/user-settings/personal/AccountPanel.vue'
import PreferencesPanel from '@feature/user-settings/personal/PreferencesPanel.vue'
import NotificationsPanel from '@feature/user-settings/personal/NotificationsPanel.vue'
import SecurityPanel from '@feature/user-settings/personal/SecurityPanel.vue'
import CreateTeamPanel from '@feature/user-settings/team/CreateTeamPanel.vue'
import PlansPanel from '@feature/user-settings/team/PlansPanel.vue'
import BillingPanel from '@feature/user-settings/team/BillingPanel.vue'
import TeamSettingsPanel from '@feature/user-settings/team/TeamSettingsPanel.vue'
import AppsPanel from '@feature/user-settings/team/AppsPanel.vue'

import MembersPanel from '@/components/feature/user-settings/team/MembersPanel.vue'

import { UserProfile } from '@/types/user'

export const navigationItems = {
  personal: {
    label: 'Personal',
    panels: [
      {
        name: 'Account',
        component: shallowRef(AccountPanel)
      },
      {
        name: 'Preferences',
        component: shallowRef(PreferencesPanel)
      },
      {
        name: 'Security',
        component: shallowRef(SecurityPanel)
      },
      {
        name: 'Notifications',
        component: shallowRef(NotificationsPanel)
      }
    ]
  },
  team: {
    label: 'Team',
    panels: [
      {
        name: 'Create',
        component: shallowRef(CreateTeamPanel)
      },
      {
        name: 'Settings',
        component: shallowRef(TeamSettingsPanel)
      },
      {
        name: 'Members',
        component: shallowRef(MembersPanel)
      },
      {
        name: 'Plans',
        component: shallowRef(PlansPanel)
      },
      {
        name: 'Billing',
        component: shallowRef(BillingPanel)
      },
      {
        name: 'Apps',
        component: shallowRef(AppsPanel)
      }
    ]
  }
}

export const userProfile = {
  profile: new UserProfile('TheBull', 'Jacob', 'Peterson', undefined),
  professionalSkills: ['Market research', 'Product design', 'Commercial planning', 'Product design', 'Commercial planning'],
  profession: 'Marketing manager',
  giphyIds: [
    'https://media3.giphy.com/media/yWAJmLu7un7BV2I46i/giphy.gif?cid=ecf05e474xwi1esbk66g4r97fgv6shipzx7jiz1q0wifuf6x&rid=giphy.gif&ct=g',
    'https://media3.giphy.com/media/yWAJmLu7un7BV2I46i/giphy.gif?cid=ecf05e474xwi1esbk66g4r97fgv6shipzx7jiz1q0wifuf6x&rid=giphy.gif&ct=g',
    'https://media3.giphy.com/media/yWAJmLu7un7BV2I46i/giphy.gif?cid=ecf05e474xwi1esbk66g4r97fgv6shipzx7jiz1q0wifuf6x&rid=giphy.gif&ct=g'
  ],
  aboutMe: `There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form,
    by injected humour, or randomised words which don't look even slightly believable.
    Variations of passages of Lorem Ipsum available, but the majority even slightly believable.
    If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of in some form. 🎉 👋
  `
}

export const selectedUser =
{
  firstName: 'Profile',
  lastName: 'One',
  displayName: 'Jane Doe',
  pictureId: '',
  location: {
    country: 'Zimbabwe',
    city: 'Gweru',
    timezone: 'Africa/Harare'
  }
}

export const userProfiles: UserProfile[] = ([
  {
    firstName: 'Profile',
    lastName: 'One',
    displayName: 'Jane Doe',
    pictureId: '',
    location: {
      country: 'Zimbabwe',
      city: 'Gweru',
      timezone: 'Africa/Harare'
    }
  },
  {
    firstName: 'Profile',
    lastName: 'Two',
    displayName: 'Jane Doe',
    pictureId: '',
    location: {
      country: 'Zimbabwe',
      city: 'Gweru',
      timezone: 'Africa/Harare'
    }
  },
  {
    firstName: 'Profile',
    lastName: 'Three',
    displayName: 'Jane Doe',
    pictureId: '',
    location: {
      country: 'Zimbabwe',
      city: 'Gweru',
      timezone: 'Africa/Harare'
    }
  },
  {
    firstName: 'Profile',
    lastName: 'Four',
    displayName: 'Jane Doe',
    pictureId: '',
    location: {
      country: 'Zimbabwe',
      city: 'Gweru',
      timezone: 'Africa/Harare'
    }
  }
])
