import { DAYS_IN_WEEK } from '@/types/global'

export default function dayNameFromDayIndex(dayIndex: string | number): string {
  switch (dayIndex.toString()) {
    case '0': return DAYS_IN_WEEK.SUNDAY
    case '1': return DAYS_IN_WEEK.MONDAY
    case '2': return DAYS_IN_WEEK.TUESDAY
    case '3': return DAYS_IN_WEEK.WEDNESDAY
    case '4': return DAYS_IN_WEEK.THURSDAY
    case '5': return DAYS_IN_WEEK.FRIDAY
    default: return DAYS_IN_WEEK.SATURDAY
  }
}
