import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "status-chip__text"
}
const _hoisted_2 = {
  key: 1,
  class: "status-chip__text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["status-chip", [
    _ctx.goalsStatus && `status-chip--${_ctx.goalsStatus}`,
    _ctx.objectiveStatus && `status-chip--${_ctx.objectiveStatus}`
  ]])
  }, [
    (_ctx.goalsStatus)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.goalsText), 1))
      : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.objectiveText), 1))
  ], 2))
}