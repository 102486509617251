import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "toggle-button" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppIcon = _resolveComponent("AppIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["toggle-button__indicator", { 'toggle-button__indicator--right' : _ctx.slideRight }])
    }, null, 2),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.toggleOptions, (option, index) => {
      return (_openBlock(), _createElementBlock("button", {
        key: index,
        class: "toggle-button__option",
        onClick: ($event: any) => (_ctx.setActiveOption(index, option.text))
      }, [
        _createElementVNode("span", null, _toDisplayString(option.text), 1),
        _createVNode(_component_AppIcon, {
          name: option.iconName
        }, null, 8, ["name"])
      ], 8, _hoisted_2))
    }), 128))
  ]))
}