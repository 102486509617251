import numberAsOrdinal from '@/filters/numberAsOrdinal'
import getMonthName from '@/filters/getMonthName'
import dayNameFromDayIndex from '@/utils/dayNameFromDayIndex'

import capitalize from '@/filters/capitalize'

export default function formatDate(date: Date, showDayName = false): string {
  const dayName = capitalize(dayNameFromDayIndex(date.getDay()))
  const year = date.getFullYear()
  const day = numberAsOrdinal(date.getDate())
  const month = getMonthName(date.getMonth())

  if (showDayName) {
    return `${dayName} ${day} ${month} ${year}`
  }

  return `${day} ${month} ${year}`
}
