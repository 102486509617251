
import { computed, defineComponent, PropType } from 'vue'
import { DatePicker } from 'v-calendar'
import 'v-calendar/dist/style.css'

import formatDate from '@/utils/formatDate'

export enum APP_CALENDAR_VARIATIONS {
  SIMPLE = 'simple'
}

export default defineComponent({
  name: 'AppCalendar',
  emits: ['update:modelValue'],
  components: {
    DatePicker
  },
  props: {
    id: {
      type: String,
      required: true
    },
    modelValue: {
      type: Date,
      default: new Date()
    },
    readOnlyDate: {
      type: Date,
      required: false
    },
    label: {
      type: String,
      required: false
    },
    type: {
      type: String as PropType<APP_CALENDAR_VARIATIONS>,
      required: false,
      validator: (value: APP_CALENDAR_VARIATIONS): boolean => Object.values(APP_CALENDAR_VARIATIONS).includes(value)
    }
  },
  setup(props, { emit }) {
    const date = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value)
    })

    return {
      date,
      APP_CALENDAR_VARIATIONS,
      formatDate
    }
  }
})
