
import { ref, defineComponent, PropType, watch } from 'vue'

export default defineComponent({
  name: 'AppToggleButton',
  emits: ['user-action:click'],
  props: {
    toggleOptions: {
      type: Array as PropType<string[]>,
      required: true
    },
    secondOptionActive: {
      type: Boolean,
      required: false
    }
  },
  setup(props, { emit }) {
    const slideRight = ref(props.secondOptionActive ?? false)

    watch(() => props.secondOptionActive, () => { slideRight.value = props.secondOptionActive ?? false })

    const setActiveOption = (index: number, option: string) => {
      slideRight.value = index !== 0
      emit('user-action:click', option)
    }

    return {
      slideRight,
      setActiveOption
    }
  }
})
