import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GoalsStatusChips = _resolveComponent("GoalsStatusChips")!
  const _component_AppSelect = _resolveComponent("AppSelect")!
  const _component_AppDropdown = _resolveComponent("AppDropdown")!

  return (_openBlock(), _createBlock(_component_AppDropdown, {
    class: "goals-status-dropdown",
    type: "simple"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_AppSelect, {
        modelValue: _ctx.selectedStatus,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedStatus) = $event)),
        options: Object.values(_ctx.SUPPORTED_GOAL_STATUSES),
        min: 1,
        "close-on-select": true
      }, {
        label: _withCtx(({ selected }) => [
          _createVNode(_component_GoalsStatusChips, { "goals-status": selected }, null, 8, ["goals-status"])
        ]),
        "dropdown-item": _withCtx(({ option }) => [
          _createVNode(_component_GoalsStatusChips, { "goals-status": option }, null, 8, ["goals-status"])
        ]),
        _: 1
      }, 8, ["modelValue", "options"])
    ]),
    _: 1
  }))
}