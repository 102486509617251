
import { defineComponent, computed } from 'vue'
import { SUPPORTED_GOAL_STATUSES, SUPPORTED_OBJECTIVE_STATUSES } from '@/data/objectives'

export default defineComponent({
  name: 'GoalsChips',
  props: {
    goalsStatus: {
      type: String,
      required: false
    },
    objectiveStatus: {
      type: String,
      required: false,
      validator: (value: SUPPORTED_OBJECTIVE_STATUSES): boolean => Object.values(SUPPORTED_OBJECTIVE_STATUSES).includes(value)
    }
  },
  setup(props) {
    const goalsText = computed(() => {
      switch (props.goalsStatus) {
        case SUPPORTED_GOAL_STATUSES.ON_HOLD: return 'On hold'
        case SUPPORTED_GOAL_STATUSES.ON_TRACK: return 'In progress'
        case SUPPORTED_GOAL_STATUSES.AT_RISK: return 'At risk'
        /* case SUPPORTED_GOAL_STATUSES.BEHIND: return 'Behind' */
        case SUPPORTED_GOAL_STATUSES.NOT_STARTED: return 'Not started'
        default: return 'Completed'
      }
    })

    const objectiveText = computed(() => {
      switch (props.objectiveStatus) {
        case SUPPORTED_OBJECTIVE_STATUSES.COMPLETED: return 'COMPLETED'
        case SUPPORTED_OBJECTIVE_STATUSES.ON_HOLD: return 'ON HOLD'
        default: return 'IN PROGRESS'
      }
    })

    return {
      goalsText,
      objectiveText
    }
  }
})
