import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toHandlers as _toHandlers, mergeProps as _mergeProps, withCtx as _withCtx, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "app-calendar" }
const _hoisted_2 = ["for"]
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_AppInput = _resolveComponent("AppInput")!
  const _component_DatePicker = _resolveComponent("DatePicker")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: _ctx.id
        }, _toDisplayString(_ctx.label), 9, _hoisted_2))
      : _createCommentVNode("", true),
    _createVNode(_component_DatePicker, {
      id: _ctx.id,
      modelValue: _ctx.date,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.date) = $event)),
      color: "purple",
      "trim-weeks": "",
      popover: { visibility: 'click' }
    }, _createSlots({ _: 2 }, [
      (_ctx.type === _ctx.APP_CALENDAR_VARIATIONS.SIMPLE)
        ? {
            name: "default",
            fn: _withCtx(({ togglePopover }) => [
              _createElementVNode("div", {
                onClick: ($event: any) => (togglePopover()),
                class: "app-calendar__handler"
              }, [
                _createVNode(_component_AppIcon, {
                  name: "flag",
                  size: "14"
                }),
                (_ctx.readOnlyDate)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.formatDate(_ctx.readOnlyDate, true)), 1))
                  : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.date ? _ctx.formatDate(_ctx.date, true) : 'Select date'), 1))
              ], 8, _hoisted_3)
            ])
          }
        : {
            name: "default",
            fn: _withCtx(({ inputValue, inputEvents }) => [
              _createVNode(_component_AppInput, _mergeProps({
                id: "app-calendar__input",
                type: "text",
                icon: "calendar",
                value: _ctx.date ? inputValue : 'Select date'
              }, _toHandlers(inputEvents)), null, 16, ["value"])
            ])
          }
    ]), 1032, ["id", "modelValue"])
  ]))
}