<template>
  <AppPanel class="preferences-panel">
    <p class="h5">Preferences</p>
    <p class="no-margin">Not yet implemented</p>

    <div style="height: 400px" />
  </AppPanel>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PreferencesPanel'
})
</script>

<style lang="scss">
.preferences-panel {
  display: flex;
  flex-direction: column;

  .h5 {
    border-bottom: 2px solid var(--color-blue-100);
    padding-bottom: 10px;
    margin: 0 0 20px;
  }
}
</style>
