
import { computed, defineComponent, PropType } from 'vue'
import { SUPPORTED_GOAL_STATUSES } from '@/data/objectives'

import AppDropdown from '@common/AppDropdown.vue'
import GoalsStatusChips from '@/components/feature/goals/GoalsStatusChips.vue'

export default defineComponent({
  name: 'GoalsStatusDropdown',
  emits: ['update:modelValue'],
  components: {
    AppDropdown,
    GoalsStatusChips
  },
  props: {
    inlineEditing: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: String as PropType<SUPPORTED_GOAL_STATUSES>,
      required: true
    }
  },
  setup(props, { emit }) {
    const selectedStatus = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value)
    })

    return {
      selectedStatus,
      SUPPORTED_GOAL_STATUSES
    }
  }
})
