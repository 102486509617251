export default function getMonthName(value: number): string {
  if (value < 0 || value > 11) {
    throw Error(`The function argument's value must be in the range of 0 to 11. Value ${value} does not meet these requirements.`)
  }

  switch (value) {
    case 0:
      return 'Jan'
    case 1:
      return 'Feb'
    case 2:
      return 'Mar'
    case 3:
      return 'Apr'
    case 4:
      return 'May'
    case 5:
      return 'Jun'
    case 6:
      return 'Jul'
    case 7:
      return 'Aug'
    case 8:
      return 'Sep'
    case 9:
      return 'Oct'
    case 10:
      return 'Nov'
    default:
      return 'Dec'
  }
}
